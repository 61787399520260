import * as React from "react"
import { Helmet } from 'react-helmet'
import { Fade } from 'react-reveal'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import arrow from '../assets/icons/arrow-down.svg'
import linkedin from '../assets/icons/linkedin.svg'
import instagram from '../assets/icons/instagram.svg'
import dribbble from '../assets/icons/dribbble.svg'
import Badge from '../components/Badge'

const CodePage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main className="bg-[url('../assets/images/background-3.webp')] bg-cover bg-no-repeat">
      <div className="flex flex-col gap-24 px-6 pt-8 text-white lg:px-32 lg:pt-24">
        {/* Jumbo */}
        {/* <div className="">
          <Fade delay={800}>
            <h3 className="pb-6 text-lg">À propos.</h3>
          </Fade>
          <Fade bottom>
            <h1 className="mb-4 font-serif text-4xl leading-tight lg:text-6xl">
              Je m'appelle Ksatra SEM
            </h1>
          </Fade>

          <Fade bottom>
            <p className="mb-16 text-lg leading-loose lg:w-3/5">
              Je suis Fullstack designer freelance basé à Toulouse. <br />
              Passionné par le design UX/UI et le développement front-end, ma
              mission est de créer des expériences numériques accessibles,
              durables et significatives.
            </p>

            <div className="mt-4 flex gap-4">
              <a>
                <img src={linkedin}></img>
              </a>
              <a>
                <img src={instagram}></img>
              </a>
              <a>
                <img src={dribbble}></img>
              </a>
            </div>
          </Fade>
        </div> */}

        {/* <Fade bottom> */}
          <div className="text-xl">
            <h3 className="pb-10 font-serif text-3xl">En plus</h3>
            <div className="flex flex-col gap-8 md:flex-row">
              <Badge title="💪 Football, Musculation" />
              <Badge title="🔊 Techno, house" />
              <Badge title="🏔 Randonnée" />
              <Badge title="🗺 Voyage" />
            </div>
          </div>
        {/* </Fade> */}
      </div>

      <Fade bottom>
        <Footer />
      </Fade>
    </main>
  )
}

export default CodePage;
